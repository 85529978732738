import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const BASE_URL =
  "https://prod-11.northcentralus.logic.azure.com:443/workflows/84f3aaaf120945b4ac853891c8e0545c/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=GjONO2Is3uZl0plG20n0CyzIemyfS_C2UAwW4dCLkS0";

const fetchTimer = async () => {
  const response = await axios.post(BASE_URL, {
    id: "9058f045-d858-47a1-92e5-be56c97904df",
  });
  if (!response.data.success) {
    throw new Error("No se pudo obtener el valor del temporizador.");
  }
  return parseInt(response.data.temporizador, 10);
};

const updateTimer = async ({ id, temporizador }) => {
  const response = await axios.post(BASE_URL, { id, temporizador });
  if (!response.data.success) {
    throw new Error(
      response.data.msj || "Error al actualizar el temporizador."
    );
  }
  return response.data;
};

export const useTimer = () => {
  const queryClient = useQueryClient();

  const timerQuery = useQuery({
    queryKey: ["timer"],
    queryFn: fetchTimer,
  });

  const timerMutation = useMutation({
    mutationFn: updateTimer,
    onMutate: async (newTimer) => {
      await queryClient.cancelQueries({ queryKey: ["timer"] });

      const previousTimer = queryClient.getQueryData(["timer"]);

      queryClient.setQueryData(["timer"], newTimer.temporizador);

      return { previousTimer };
    },
    onError: (err, newTimer, context) => {
      queryClient.setQueryData(["timer"], context.previousTimer);
    },
    onSuccess: (data) => {
      console.log("Mutación exitosa:", data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["timer"] });
    },
  });

  return { timerQuery, timerMutation };
};
