import axios from "axios";
export const getMachinesLedesma = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getVehiculosLedesma"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};
export const getSupervisorsLedesma = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getPersonalLedesma"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};
export const getBlocksLedesma = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getBloquesLedesma"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};
export const getMachines = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getVehiculos"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};

export const getSupervisors = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getPersonal"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};

export const getBlocks = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getBloques"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};

export const getDrivers = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getChoferesCamionCisterna"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};

export const getTrucks = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getCamiones"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};

export const getProduct = async () => {
  try {
    const res = await axios.get(
      "https://caldenflow.cn-grupo.net:15443/api/getProductos"
    );
    return res.data;
  } catch (error) {
    console.error("Error al obtener los camiones:", error);
    return null;
  }
};
