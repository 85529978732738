import React, { useEffect, useState } from "react";
import { useTimer } from "../utils/useTimer";
import {
  TextField,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  List,
  ListItem,
  Skeleton,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import { getConfig } from "../API/config";

const TimerConfig = () => {
  const [inputValue, setInputValue] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState(null);
  const [errorDetails, setErrorDetails] = useState(null);
  const [loadingErrors, setLoadingErrors] = useState(true);

  const { timerQuery, timerMutation } = useTimer();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoadingErrors(true); // Mostrar spinner mientras carga
        const config = await getConfig();
        console.log(config);

        // Parsear cadenas JSON si es necesario
        const patentesRepetidas = Array.isArray(config.patentesRepetidas)
          ? config.patentesRepetidas
          : JSON.parse(config.patentesRepetidas || "[]");
        const remitosRepetidos = Array.isArray(config.remitosRepetidos)
          ? config.remitosRepetidos
          : JSON.parse(config.remitosRepetidos || "[]");

        let repeatedPatents = [];
        let repeatedRemitos = [];

        if (patentesRepetidas.length > 0) {
          repeatedPatents = patentesRepetidas.map(
            (item) => `${item.numeroVenta}, ${item.patente}`
          );
        }

        if (remitosRepetidos.length > 0) {
          repeatedRemitos = remitosRepetidos.flatMap((remito) =>
            remito.otrosDatos.map(
              (dato) => `${remito.remito}, ${dato.patente}, ${dato.numeroVenta}`
            )
          );
        }

        if (repeatedPatents.length > 0 || repeatedRemitos.length > 0) {
          setErrorDetails({
            repeatedPatents,
            repeatedRemitos,
          });
        } else {
          setErrorDetails(null); // No hay errores
        }
      } catch (error) {
        console.error("Error al obtener la configuración:", error);
      } finally {
        setLoadingErrors(false); // Ocultar spinner al terminar
      }
    };
    fetchConfig();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const numericValue = parseInt(inputValue, 10);
    if (isNaN(numericValue) || numericValue < 0) {
      setResponseStatus(false);
      setResponseMessage("Por favor, ingrese un número válido.");
      setSnackbarOpen(true);
      return;
    }

    timerMutation.mutate(
      {
        id: "9058f045-d858-47a1-92e5-be56c97904df",
        temporizador: numericValue,
      },
      {
        onSuccess: (data) => {
          setInputValue("");
          setResponseStatus(true);
          setResponseMessage(
            data.msj || "Temporizador actualizado correctamente."
          );
        },
      }
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        gap: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Configuraciones
      </Typography>

      {timerQuery.isLoading ? (
        <Skeleton width={200} height={40} />
      ) : timerQuery.error ? (
        <Typography color="error">Error al cargar el temporizador.</Typography>
      ) : (
        <Typography variant="h6">
          Tiempo del temporizador: {timerQuery.data} minutos
        </Typography>
      )}

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <TextField
          label="Tiempo (minutos)"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={timerMutation.isPending}
          sx={{
            marginTop: 2,
          }}
        >
          {timerMutation.isPending ? "Actualizando..." : "Actualizar"}
        </Button>
      </Box>

      {loadingErrors ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <CircularProgress />
        </Box>
      ) : errorDetails ? (
        <Box
          sx={{
            position: "fixed",
            top: 100,
            right: 50,
            zIndex: 1300,
            maxWidth: 400,
            width: "100%",
          }}
        >
          <Alert
            severity="error"
            sx={{ maxWidth: 400, width: "100%", overflowX: "auto" }}
          >
            {errorDetails.repeatedPatents.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Patentes Repetidas
                </Typography>
                <Box
                  sx={{
                    display: "table",
                    width: "100%",
                    borderCollapse: "collapse",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "table-row", fontWeight: "bold" }}>
                    <Box
                      sx={{
                        display: "table-cell",
                        padding: 1,
                        borderBottom: 1,
                      }}
                    >
                      Nº Venta
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        padding: 1,
                        borderBottom: 1,
                      }}
                    >
                      Patente
                    </Box>
                  </Box>
                  {errorDetails.repeatedPatents.map((detail, index) => {
                    const [numeroVenta, patente] = detail.split(", ");
                    return (
                      <Box key={index} sx={{ display: "table-row" }}>
                        <Box sx={{ display: "table-cell", padding: 1 }}>
                          {numeroVenta}
                        </Box>
                        <Box sx={{ display: "table-cell", padding: 1 }}>
                          {patente}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
            {errorDetails.repeatedRemitos.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Remitos Repetidos
                </Typography>
                <Box
                  sx={{
                    display: "table",
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <Box sx={{ display: "table-row", fontWeight: "bold" }}>
                    <Box
                      sx={{
                        display: "table-cell",
                        padding: 1,
                        borderBottom: 1,
                      }}
                    >
                      Remito
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        padding: 1,
                        borderBottom: 1,
                      }}
                    >
                      Patente
                    </Box>
                    <Box
                      sx={{
                        display: "table-cell",
                        padding: 1,
                        borderBottom: 1,
                      }}
                    >
                      Nº Venta
                    </Box>
                  </Box>
                  {errorDetails.repeatedRemitos.map((detail, index) => {
                    const [remito, patente, numeroVenta] = detail.split(", ");
                    return (
                      <Box key={index} sx={{ display: "table-row" }}>
                        <Box sx={{ display: "table-cell", padding: 1 }}>
                          {remito}
                        </Box>
                        <Box sx={{ display: "table-cell", padding: 1 }}>
                          {patente}
                        </Box>
                        <Box sx={{ display: "table-cell", padding: 1 }}>
                          {numeroVenta}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Alert>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: 100,
            right: 50,
            zIndex: 1300,
            maxWidth: 400,
            width: "100%",
          }}
        >
          <Alert severity="success" sx={{ maxWidth: 400, width: "100%" }}>
            No se encontraron errores.
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default TimerConfig;
